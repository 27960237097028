import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import ScrollProvider from 'components/ScrollProvider';
import { NavigationProvider } from 'components/Navigation';

import StickyNavigation from 'components/StickyNavigation';

import AppPageIntro from 'components/AppPageIntro';
import PlatformOverview from 'components/PlatformOverview';
import PilotPlan from 'components/PilotPlan';
import SiteFooter from 'components/SiteFooter';

import './flow.scss';

class RootIndex extends React.Component {
    render() {
        const { site } = get(this, 'props.pageContext');
        const {
            title,
            heading,
            introText,
            introCallToAction,
            introVideo,
            introBackground,
            narrative,
            platformHeading,
            platformAnchor,
            platformText,
            platformCallToAction,
            platformNarrative,
            pilotPlan,
        } = get(this, 'props.data.flow');

        return (
            <ScrollProvider>
                <NavigationProvider>
                    <div>
                      <Helmet>
                        <title>{title || site.title || 'Mira'}</title>
                      </Helmet>


                        <StickyNavigation
                            sticky={true}
                            pages={site.navigationItems}
                            sections={[]}
                            loginLink={site.loginLink}
                            callToAction={site.callToAction}
                        />
                        <AppPageIntro
                            heading={heading}
                            text={introText}
                            call={introCallToAction}
                            modal={introVideo}
                            background={get(
                                introBackground,
                                'file.url',
                            )}
                            useSvgBg={false}
                        />
                        <PlatformOverview
                            heading={platformHeading}
                            anchor={platformAnchor}
                            intro={platformText}
                            call={platformCallToAction}
                            narrative={platformNarrative}
                        />
                        <PilotPlan {...pilotPlan} />
                        <SiteFooter
                            links={site.footerNavigationItems}
                            social={site}
                            copyright={site.copyright}
                        />
                    </div>
                </NavigationProvider>
            </ScrollProvider>
        );
    }
}

export default RootIndex;

export const pageQuery = graphql`
    query FlowPageQuery($id: String!) {
        site: allContentfulWebsite {
            edges {
                node {
                    id
                    title
                    navigationItems {
                        label
                        href
                    }
                    callToAction {
                        label
                        href
                    }
                    loginLink {
                        label
                        href
                    }
                    footerNavigationItems {
                        label
                        href
                    }
                    facebook
                    instagram
                    twitter
                    linkedin
                    copyright
                }
            }
        }
        flow: contentfulFlowPage(contentful_id: { eq: $id }) {
            id: contentful_id
            title
            heading
            introText
            introCallToAction
            introVideo
            introBackground {
                file {
                    url
                }
            }
            platformHeading
            platformAnchor {
                label
                slug
            }
            platformText
            platformCallToAction
            platformNarrative {
                id
                heading
                text
                backgroundImage {
                    file {
                        url
                    }
                }
                backgroundImageBlurred {
                    file {
                        url
                    }
                }
                overlayImage {
                    file {
                        url
                    }
                }
                call: callToAction {
                    label
                    href
                }
            }
            pilotPlan {
                heading
                text
                call: callToAction {
                    label
                    href
                }
            }
        }
    }
`;
